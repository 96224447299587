import 'react-app-polyfill/stable'
import React from 'react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import store from './config/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
